<template>
  <Header v-if="!route.path.includes('screen')" />
  <div class="layout">
    <router-view />
  </div>
</template>
<script>
import { store, orders, balance, ordersList } from '@/data/menu'
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return {
      route: this.$router.currentRoute,
      store,
      orders,
      balance,
      ordersList,
      images: ['house', 'house-2', 'house-3', 'house-4']
    }
  },
  methods: {
    async getMenu() {
      const result = await fetch('https://api.veresk.club/pizza/menu', {
        method: 'POST',
        body: ''
      })
      const data = await result.json()
      store.menu = data.filter(category => category.items.length)
      const saved = window.localStorage.getItem('orders')
      !orders.length && saved && orders.push(...JSON.parse(saved).flat())

      const s = window.localStorage.getItem('ordersList')
      !ordersList.length && s && ordersList.push(...JSON.parse(s).flat())

      const stops = await fetch('https://api.veresk.club/pizza/menu/stop_list')
      const balanceArr = await stops.json()

      balance.push(...balanceArr)
      console.log(balance);

      console.log('Fuck',
        store.menu.map(cat => {
          return cat.items.map(
            item =>
              (item.balance =
                balance.find(bItem => item.id === bItem.productId) &&
                balance.find(bItem => item.id === bItem.productId).balance)
          )
        })
      )
      console.log("store",store)
      // console.log(store.menu)
    }
  },
  mounted() {
    this.getMenu()
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
pre {
  font-size: 0.7rem;
}
.layout {
  /* background: rgb(255, 142, 142); */
  box-sizing: border-box;
  /* min-height: 100vh; */
  display: grid;
  grid-gap: var(--gap);
  max-width: 1400px;
  min-width: 1020px;
  width: 100%;
  margin: 0 auto;
  padding: var(--layoutPadding);
  position: relative;
}
body {
  font-size: 18px;
}
h1,
h2,
h3 {
  text-transform: uppercase;
  font-weight: 400;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.2em;
}
h4 {
  font-size: 1em;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .layout {
    min-width: 796px;
  }
}
@media (max-width: 800px) {
  .layout {
    min-width: unset;
  }
  body {
    font-size: 15px;
  }
}
</style>
