module.exports = {
  //* Пицца
  Маргарита: 'margarita.jpg',
  "Пицца Маргарита 20см": 'margarita.jpg',
  "Пицца Маргарита 30см": 'margarita.jpg',
  'Пицца 4 сыра 20см': '4formago.jpg',
  'Пицца 4 сыра 30см': '4formago.jpg',
  'Пицца Курица-Песто 20см': 'pesto.jpg',
  'Пицца Курица-Песто 30см': 'pesto.jpg',
  'Прошутто с рукколой': 'proshuto.jpg',
  Чоризо: 'chorizo.jpg',
  "Пицца Чоризо 20см": 'chorizo.jpg',
  "Пицца Чоризо 30см": 'chorizo.jpg',
  'A la russe': 'alarus.jpg',
  'Груша горгонзола': 'gorgonzola.jpg',
  "Пицца Мясная 20см": 'meat.jpg',
  "Пицца Мясная 30см": 'meat.jpg',
  "Пицца с шампиньонами и страчателлой 20см": 'fungi.jpg',
  "Пицца с шампиньонами и страчателлой 30см": 'fungi.jpg',
  "Пицца с печеными овощами 20см": 'vegi.jpg',
  "Пицца с печеными овощами 30см": 'vegi.jpg',
  "Пицца Деревенская 20см": 'derevenskaya.jpg',
  "Пицца Деревенская 30см": 'derevenskaya.jpg',
  'Курица-кари с ананасом': 'carry-pinapple.jpg',
  'С пастрами': 'pastrami.jpg',
  'Кролл Альпийский': 'kroll_alpen.jpg',
  'Кролл слососем': 'croll_los.jpg',
  'Кролл с Лососем': 'croll_los.jpg',
  'Кролл с лососем': 'croll_los.jpg',
  'Пицца с брискетом и вялеными томатами': 'brisket.jpg',
  'Пицца с томатами и страчателлой': 'strachetello.jpg',
  'Картофельный ролл с грибами': 'kroll_mushrooom.jpg',

  //* выпечка
  Трдельник: 'terdelnik.jpg',
  'Мини-пышки': 'minipichki.jpg',
  Мороженое: 'ice-cream.jpg',
  'Маракуйя-горгонзола': 'maraqua-gorgonzolla.jpg',
  Панакота: 'panacota.jpg',
  'Булочка с корицей': 'koriza.jpg',
  'Тарталетка лимонная с меренгой': 'merenga.jpg',
  'Маффин черничный': 'maffin.jpg',
  'Тарталетка карамельно-ореховая': 'oreh.jpg',

  //* хлеб
  'Фокачча с томатами и оливками': 'chiabatta.jpg',
  Мультизлаковый: 'multi-bread.jpg',
  'Луковый хлеб с тмином': 'onion-bred.jpg',
  'Кукурузный хлеб': 'corn-bread.jpg',

  //* Напитки
  'Малиновый чай': 'cup.jpg',
  'Облепиховый чай': 'cup.jpg',
  Глинтвейн: 'cup.jpg',
  'Чай пакет': 'cup.jpg',
  'Горячий напиток вишня/корица': 'cup.jpg',
  'Облипиховый чай': 'cup.jpg',
  'Пиво б/а': 'beer.jpg',
  'Coca-Cola': 'cola.jpg',
  'Лимонад вишня-лемонграсс': 'cup.jpg',
  'Лимонад грейфрут-розмарин': 'cup.jpg',
  'Квас очаковский ж/б 0.5': 'kvas.jpg',
  'Лимонад Черноголовка': 'tarhun.jpg',
  Вода: 'aqua.jpg',
  //* Кофе
  Американо: 'cup.jpg',
  'Американо с молоком': 'cup.jpg',
  Капучино: 'cup.jpg'
}
